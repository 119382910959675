












































import { Color, colors } from '@/design/system/helpers'
import { computed, defineComponent, PropType } from '@vue/composition-api'

type ButtonType = 'button' | 'reset' | 'submit'
export type ButtonVariant = 'solid' | 'ghost' | 'soft' | 'transparent'

export const buttonToneConfig: Record<
  Color,
  Record<ButtonVariant, (string | Record<string, boolean>)[]>
> = {
  primary: {
    solid: [
      'text-primary-inverse',
      'bg-primary',
      'hover:bg-primary-400',
      'active:bg-primary-600',
    ],
    ghost: [],
    soft: [
      'text-primary',
      'hover:bg-primary-50',
      'dark:hover:border-primary-700',
      'dark:hover:bg-primary-700',
      'dark:text-primary-100',
      'dark:hover:bg-opacity-40',
    ],
    transparent: [],
  },
  secondary: {
    solid: [
      'text-secondary-inverse',
      'bg-secondary',
      'hover:bg-secondary-400',
      'active:bg-secondary-600',
    ],
    ghost: [],
    soft: [
      'text-secondary',
      'hover:bg-secondary-50',
      'dark:hover:bg-secondary-700',
      'dark:text-secondary-100',
      'dark:hover:bg-secondary-40',
    ],
    transparent: [],
  },
  tertiary: {
    solid: [
      'text-tertiary-inverse',
      'bg-tertiary',
      'hover:bg-tertiary-400',
      'active:bg-tertiary-600',
    ],
    ghost: [],
    soft: [
      'text-tertiary',
      'hover:bg-tertiary-50',
      'dark:hover:bg-tertiary-700',
      'dark:text-tertiary-100',
      'dark:hover:bg-opacity-40',
    ],
    transparent: [],
  },
  quaternary: {
    solid: [
      'text-quaternary-inverse',
      'bg-quaternary',
      'hover:bg-quaternary-600',
      'active:bg-quaternary-800',
    ],
    ghost: [],
    soft: [
      'text-quaternary',
      'hover:bg-quaternary-50',
      'dark:hover:bg-quaternary-700',
      'dark:text-quaternary-100',
      'dark:hover:bg-opacity-40',
    ],
    transparent: [],
  },
  critical: {
    solid: [
      'text-critical-inverse',
      'bg-critical',
      'hover:bg-critical-400',
      'active:bg-critical-600',
    ],
    ghost: [],
    soft: [
      'text-critical',
      'hover:bg-critical-50',
      'dark:hover:bg-critical-700',
      'dark:text-critical-100',
      'dark:hover:bg-opacity-40',
    ],
    transparent: [],
  },
  caution: {
    solid: [
      'text-caution-inverse',
      'bg-caution',
      'hover:bg-caution-400',
      'active:bg-caution-600',
    ],
    ghost: [],
    soft: [
      'text-caution',
      'hover:bg-caution-50',
      'dark:hover:bg-caution-700',
      'dark:text-caution-100',
      'dark:hover:bg-opacity-40',
    ],
    transparent: [],
  },
  positive: {
    solid: [
      'text-positive-inverse',
      'bg-positive',
      'hover:bg-positive-400',
      'active:bg-positive-600',
    ],
    ghost: [],
    soft: [
      'text-positive',
      'hover:bg-positive-50',
      'dark:hover:bg-positive-700',
      'dark:text-positive-100',
      'dark:hover:bg-opacity-40',
    ],
    transparent: [],
  },
  neutral: {
    solid: [
      'text-neutral-inverse',
      'bg-neutral',
      'hover:bg-neutral-400',
      'active:bg-neutral-600',
    ],
    ghost: [],
    soft: [
      'text-neutral',
      'hover:bg-neutral-50',
      'dark:hover:bg-neutral-700',
      'dark:text-neutral-100',
      'dark:hover:bg-opacity-40',
    ],
    transparent: [],
  },
  info: {
    solid: [
      'text-info-inverse',
      'bg-info',
      'hover:bg-info-400',
      'active:bg-info-600',
    ],
    ghost: [],
    soft: [
      'text-info',
      'hover:bg-info-50',
      'dark:hover:bg-info-700',
      'dark:text-info-100',
      'dark:hover:bg-opacity-40',
    ],
    transparent: [],
  },
  promote: {
    solid: [
      'text-promote-inverse',
      'bg-promote',
      'hover:bg-promote-400',
      'active:bg-promote-600',
    ],
    ghost: [],
    soft: [
      'text-promote',
      'hover:bg-promote-50',
      'dark:hover:bg-promote-700',
      'dark:text-promote-100',
      'dark:hover:bg-opacity-40',
    ],
    transparent: [],
  },
}

export const buttonBaseClasses = [
  'w-full',
  'sm:w-auto',
  'font-mono',
  'font-semibold',
  'leading-6',
  'text-lg',
  'text-center',
  'py-3',
  'px-4',
  'md:px-6',
  'rounded-md',
  'transition-colors',
  'duration-200',
  'shadow-lg',
  'focus:outline-none',
  'focus-visible:ring-2',
  'focus-visible:ring-primary-300',
  'dark:focus-visible:ring-gray-200',
  'dark:bg-opacity-90',
]

export const buttonVariantConfig: Record<ButtonVariant, string[]> = {
  solid: [],
  ghost: [],
  soft: [],
  transparent: [],
}

export default defineComponent({
  name: 'BaseButton',

  props: {
    type: {
      type: String as PropType<ButtonType>,
      default: 'button',
      validator: (value: string) =>
        ['button', 'reset', 'submit'].includes(value),
    },

    tone: {
      type: String as PropType<Color>,
      default: 'neutral' as Color,
      validator: (value: Color) => colors.includes(value),
    },

    variant: {
      type: String as PropType<ButtonVariant>,
      default: 'solid' as ButtonVariant,
      validator: (value: string) =>
        ['solid', 'ghost', 'soft', 'transparent'].includes(value),
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const computedClass = computed(() => [
      ...buttonBaseClasses,
      ...buttonToneConfig[props.tone][props.variant],
      ...buttonVariantConfig[props.variant],
      ...(props.disabled ? ['opacity-50'] : []),
      ...(props.loading ? ['relative pointer-events-none select-none'] : []),
    ])

    const computedAttributes = computed(() => ({
      disabled: props.disabled || props.loading,
    }))

    return {
      computedClass,
      computedAttributes,
    }
  },
})
