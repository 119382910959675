import { OTF } from './formats'

export const MontserratFont = [
  {
    family: 'Montserrat',
    source: '/fonts/Montserrat-Bold.otf',
    format: OTF,
    descriptors: {
      style: 'normal',
      weight: 700,
    },
  },
  {
    family: 'Montserrat',
    source: '/fonts/Montserrat-Regular.otf',
    format: OTF,
    descriptors: {
      style: 'normal',
      weight: 400,
    },
  },
  {
    family: 'Montserrat',
    source: '/fonts/Montserrat-Light.otf',
    format: OTF,
    descriptors: {
      style: 'normal',
      weight: 300,
    },
  },
]

export default [...MontserratFont]
