



































































import { ThemeVariant } from '@/enums/ThemeVariant'
import { TranslateResult } from 'vue-i18n'
import { Prop } from 'vue/types/options'
import Vue from 'vue'
import { HTMLElementEvent } from '@/types/HTMLElementEvent'

export type SegmentedControlOption = {
  id: string
  label: string | TranslateResult
  value: any
  default?: boolean
  disabled?: boolean
}

let uid = 0

// TODO: Support Object and Array values for SegmentedControlOption
export default Vue.extend({
  name: 'BaseSegmentedControl',

  model: {
    event: 'update',
  },

  props: {
    name: {
      type: String,
      default: '',
    },

    value: {
      type: [String, Boolean, Number, Object, Array],
      default: () => [],
    },

    options: {
      type: Array as Prop<SegmentedControlOption[]>,
      default: () => [],
    },

    variant: {
      type: String as () => ThemeVariant,
      default: ThemeVariant.Secondary,
    },

    multiple: {
      type: Boolean,
      default: false,
    },

    round: {
      type: Boolean,
      default: false,
    },

    vertical: {
      type: Boolean,
      default: false,
    },

    trackBy: {
      type: String,
      default: 'id',
    },
  },

  data() {
    return {
      uid: 0,
    }
  },

  computed: {
    control(): string {
      return this.multiple ? 'checkbox' : 'radio'
    },

    internalValue: {
      get(): any[] {
        if (this.multiple) {
          return Array.isArray(this.value) ? this.value : [this.value]
        }
        return this.value
      },
      set(value: any) {
        this.emitValue(value)
      },
    },
  },

  created() {
    this.assignUniqueId()
  },

  methods: {
    assignUniqueId() {
      this.uid = uid += 1
    },

    getOptionUniqueId(option: SegmentedControlOption): string {
      return `sc-${this.uid}-o-${option.id}`
    },

    getDefaultOptionValue(): SegmentedControlOption {
      const defaultOption = this.options.find((option) => !!option.default)
      return defaultOption ? defaultOption.value : null
    },

    emitValue(value: any) {
      if (this.multiple) {
        this.$emit('update', this.internalValue.concat(value))
      } else {
        this.$emit('update', value)
      }
    },

    handleLabelKeydown(ev: HTMLElementEvent<HTMLLabelElement>): void {
      ev.target.click()
    },
  },
})
