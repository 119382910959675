






import { Color, colors } from '@/design/system/helpers'
import { computed, defineComponent, PropType } from '@vue/composition-api'

type BadgeWeight = 'strong' | 'regular' | 'weak'

export default defineComponent({
  name: 'BaseBadge',

  props: {
    tag: {
      type: String,
      default: 'span',
    },

    tone: {
      type: String as PropType<Color>,
      default: 'neutral' as Color,
      validator: (value: Color) => colors.includes(value),
    },

    weight: {
      type: String as PropType<BadgeWeight>,
      default: 'regular',
      validator: (value: string) =>
        ['strong', 'regular', 'weak'].includes(value),
    },
  },

  setup(props) {
    const badgeToneConfig: Record<
      Color,
      Record<BadgeWeight, (string | Record<string, boolean>)[]>
    > = {
      primary: {
        strong: ['border-primary', 'bg-primary', 'text-primary-inverse'],
        regular: [
          'border-primary-50',
          'bg-primary-50',
          'text-primary-700',
          'dark:border-primary-700',
          'dark:bg-primary-700',
          'dark:text-primary-100',
        ],
        weak: ['border-primary', 'text-primary', 'dark:text-primary-200'],
      },
      secondary: {
        strong: ['border-secondary', 'bg-secondary', 'text-secondary-inverse'],
        regular: [
          'border-secondary-50',
          'bg-secondary-50',
          'text-secondary-700',
          'dark:border-secondary-700',
          'dark:bg-secondary-700',
          'dark:text-secondary-100',
        ],
        weak: ['border-secondary', 'text-secondary', 'dark:text-secondary-200'],
      },
      tertiary: {
        strong: ['border-tertiary', 'bg-tertiary', 'text-tertiary-inverse'],
        regular: [
          'border-tertiary-50',
          'bg-tertiary-50',
          'text-tertiary-700',
          'dark:border-tertiary-700',
          'dark:bg-tertiary-700',
          'dark:text-tertiary-100',
        ],
        weak: ['border-tertiary', 'text-tertiary', 'dark:text-tertiary-200'],
      },
      quaternary: {
        strong: [
          'border-quaternary',
          'bg-quaternary',
          'text-quaternary-inverse',
        ],
        regular: [
          'border-quaternary-50',
          'bg-quaternary-50',
          'text-quaternary-700',
          'dark:border-quaternary-700',
          'dark:bg-quaternary-700',
          'dark:text-quaternary-100',
        ],
        weak: [
          'border-quaternary',
          'text-quaternary',
          'dark:text-quaternary-200',
        ],
      },
      critical: {
        strong: ['border-critical', 'bg-critical', 'text-critical-inverse'],
        regular: [
          'border-critical-50',
          'bg-critical-50',
          'text-critical-700',
          'dark:border-critical-700',
          'dark:bg-critical-700',
          'dark:text-critical-100',
        ],
        weak: ['border-critical', 'text-critical', 'dark:text-critical-200'],
      },
      caution: {
        strong: ['border-caution', 'bg-caution', 'text-caution-inverse'],
        regular: [
          'border-caution-50',
          'bg-caution-50',
          'text-caution-700',
          'dark:border-caution-700',
          'dark:bg-caution-700',
          'dark:text-caution-100',
        ],
        weak: ['border-caution', 'text-caution', 'dark:text-caution-200'],
      },
      positive: {
        strong: ['border-positive', 'bg-positive', 'text-positive-inverse'],
        regular: [
          'border-positive-50',
          'bg-positive-50',
          'text-positive-700',
          'dark:border-positive-700',
          'dark:bg-positive-700',
          'dark:text-positive-100',
        ],
        weak: ['border-positive', 'text-positive', 'dark:text-positive-200'],
      },
      neutral: {
        strong: ['border-neutral', 'bg-neutral', 'text-neutral-inverse'],
        regular: [
          'border-neutral-50',
          'bg-neutral-50',
          'text-neutral-700',
          'dark:border-neutral-700',
          'dark:bg-neutral-700',
          'dark:text-neutral-100',
        ],
        weak: ['border-neutral', 'text-neutral', 'dark:text-neutral-200'],
      },
      info: {
        strong: ['border-info', 'bg-info', 'text-info-inverse'],
        regular: [
          'border-info-50',
          'bg-info-50',
          'text-info-700',
          'dark:border-info-700',
          'dark:bg-info-700',
          'dark:text-info-100',
        ],
        weak: ['border-info', 'text-info', 'dark:text-info-200'],
      },
      promote: {
        strong: ['border-promote', 'bg-promote', 'text-promote-inverse'],
        regular: [
          'border-promote-50',
          'bg-promote-50',
          'text-promote-700',
          'dark:border-promote-700',
          'dark:bg-promote-700',
          'dark:text-promote-100',
        ],
        weak: ['border-promote', 'text-promote', 'dark:text-promote-200'],
      },
    }

    const badgeWeightConfig: Record<
      BadgeWeight,
      (string | Record<string, boolean>)[]
    > = {
      strong: ['dark:border-opacity-70', 'dark:bg-opacity-70'],
      regular: ['dark:border-opacity-40', 'dark:bg-opacity-40'],
      weak: ['dark:border-opacity-70', 'dark:text-opacity-70'],
    }

    const computedClass = computed(() => [
      'inline-block',
      'rounded-full',
      'px-2',
      'border',
      'truncate',
      'text-sm',
      'text-center',
      'font-medium',
      '',
      ...badgeToneConfig[props.tone][props.weight],
      ...badgeWeightConfig[props.weight],
    ])

    return {
      computedClass,
    }
  },
})
