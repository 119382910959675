










import Vue from 'vue'

export default Vue.extend({
  name: 'BaseTextarea',

  inheritAttrs: false,

  model: {
    event: 'update',
  },
})
