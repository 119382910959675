









import { inBrowser } from '@/utils/dom'
import Vue from 'vue'

export default Vue.extend({
  name: 'BaseModal',

  model: {
    prop: 'modelValue',
    event: 'update',
  },

  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },

    closeOnBackdropClick: {
      type: Boolean,
      default: true,
    },

    closeWithEscapeKey: {
      type: Boolean,
      default: true,
    },

    preventBodyScroll: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      scrollSavedPosition: {
        x: 0,
        y: 0,
      },
    }
  },

  computed: {
    backdropEventName(): string | null {
      return this.closeOnBackdropClick ? 'click' : null
    },
  },

  created() {
    if (this.closeWithEscapeKey && inBrowser) {
      const eventType = 'keydown'

      document.addEventListener(eventType, this.escapeKeyListener)

      this.$once('destroyed', () => {
        document.removeEventListener(eventType, this.escapeKeyListener)
      })
    }

    if (this.preventBodyScroll && inBrowser) {
      this.$watch('modelValue', (newVal: boolean) => {
        if (newVal) {
          this.removeBodyScroll()
        } else {
          this.addBodyScroll()
        }
      })

      this.$once('beforeDestroy', () => {
        this.addBodyScroll()
      })
    }
  },

  methods: {
    close(): void {
      this.$emit('update', false)
    },

    addBodyScroll(): void {
      const { body } = document
      body.style.top = ''
      body.style.left = ''
      body.dataset.noScroll = 'false'

      this.$nextTick(() => {
        window.scrollTo(this.scrollSavedPosition.x, this.scrollSavedPosition.y)
      })
    },

    removeBodyScroll(): void {
      const { body } = document
      const { scrollX, scrollY } = window

      this.scrollSavedPosition.x = scrollX
      this.scrollSavedPosition.y = scrollY

      body.style.left = `-${scrollX}px`
      body.style.top = `-${scrollY}px`
      body.dataset.noScroll = 'true'
    },

    escapeKeyListener(evt: KeyboardEvent): void {
      if (
        evt.key !== 'Escape' ||
        !this.modelValue ||
        (evt.target &&
          ['INPUT', 'TEXTAREA'].includes((evt.target as HTMLElement).nodeName))
      )
        return

      this.close()
    },
  },
})
