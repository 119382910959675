var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'segmented-control',
    {
      'segmented-control--round': _vm.round,
    } ]},[_c('transition',[_c('ul',{class:[
        'segmented-control__list',
        {
          'segmented-control__list--vertical': _vm.vertical,
        } ]},_vm._l((_vm.options),function(option){
      var _obj;
return _c('li',{key:option.label,class:[
          'segmented-control__item',
          {
            'segmented-control__item--vertical': _vm.vertical,
          } ],attrs:{"data-selected":!_vm.multiple
            ? _vm.internalValue === option.value
            : _vm.internalValue.includes(option.value)}},[_c('label',{class:[
            'segmented-control__label',
            ( _obj = {}, _obj[("button--" + _vm.variant)] = !_vm.multiple
                ? _vm.internalValue === option.value
                : _vm.internalValue.includes(option.value), _obj['segmented-control__label--vertical'] =  _vm.vertical, _obj ) ],attrs:{"for":_vm.getOptionUniqueId(option),"data-selected":!_vm.multiple
              ? _vm.internalValue === option.value
              : _vm.internalValue.includes(option.value),"tabindex":"0"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }return _vm.handleLabelKeydown($event)}}},[_vm._v(" "+_vm._s(option.label)+" ")]),((_vm.control)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.internalValue),expression:"internalValue"}],staticClass:"segmented-control__input",attrs:{"id":_vm.getOptionUniqueId(option),"type":"checkbox"},domProps:{"value":option.value,"checked":Array.isArray(_vm.internalValue)?_vm._i(_vm.internalValue,option.value)>-1:(_vm.internalValue)},on:{"change":function($event){var $$a=_vm.internalValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=option.value,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.internalValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.internalValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.internalValue=$$c}}}}):((_vm.control)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.internalValue),expression:"internalValue"}],staticClass:"segmented-control__input",attrs:{"id":_vm.getOptionUniqueId(option),"type":"radio"},domProps:{"value":option.value,"checked":_vm._q(_vm.internalValue,option.value)},on:{"change":function($event){_vm.internalValue=option.value}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.internalValue),expression:"internalValue"}],staticClass:"segmented-control__input",attrs:{"id":_vm.getOptionUniqueId(option),"type":_vm.control},domProps:{"value":option.value,"value":(_vm.internalValue)},on:{"input":function($event){if($event.target.composing){ return; }_vm.internalValue=$event.target.value}}})])}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }