



































import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'BaseFieldLabel',

  props: {
    htmlFor: {
      type: String,
      required: true,
    },

    label: {
      type: String,
      default: '',
    },

    secondaryLabel: {
      type: String,
      default: '',
    },

    tertiaryLabel: {
      type: String,
      default: '',
    },

    description: {
      type: String,
      default: '',
    },

    descriptionId: {
      type: String,
      default: '',
    },
  },

  setup(props, context) {
    const hasSecondaryLabel = computed(() =>
      Boolean(props.secondaryLabel || context.slots.secondaryLabel)
    )
    const hasTertiaryLabel = computed(() =>
      Boolean(props.tertiaryLabel || context.slots.tertiaryLabel)
    )
    const hasDescription = computed(() =>
      Boolean(props.description || context.slots.description)
    )

    return {
      hasSecondaryLabel,
      hasTertiaryLabel,
      hasDescription,
    }
  },
})
