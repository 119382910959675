
















import { useColorScheme } from '@/composition/useColorScheme'
import PwaNewContent from '@/components/pwa/PwaNewContent.vue'
import { auth } from '@/composition/auth'
import { currentRoute } from '@/router'
import i18n from '@/setup/i18n'
import appConfig from '@/app.config.json'
import { CommuniqueContainer } from 'vue-communique'
import { gsap } from 'gsap'
import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'App',

  metaInfo: {
    titleTemplate(title) {
      // All subcomponent titles will be injected into this template.
      return title ? `${i18n.t(title)} - ${appConfig.title}` : appConfig.title
    },
  },

  components: {
    CommuniqueContainer,
    PwaNewContent,
  },

  setup() {
    const layoutName = computed(() =>
      auth.loggedIn.value &&
      currentRoute.value?.matched.some((r) => r.meta.authRequired)
        ? 'LayoutAdmin'
        : 'LayoutDefault'
    )

    const layoutComponent = computed(() =>
      currentRoute.value &&
      currentRoute.value.meta &&
      currentRoute.value.meta.layout
        ? currentRoute.value.meta.layout
        : () =>
            import(
              /* webpackChunkName: '[request]' */
              `@/layouts/${layoutName.value}.vue`
            )
    )

    const layoutBeforeEnter = (el: Element): void => {
      gsap.set(el, {
        opacity: 0.5,
      })
    }

    const layoutEnter = (el: Element, done: () => void): void => {
      gsap.to(el, {
        duration: 0.3,
        opacity: 1,
        ease: 'linear',
        clearProps: 'all',
        onComplete: done,
      })
    }

    const layoutLeave = (el: Element, done: () => void): void => {
      gsap.to(el, {
        duration: 0.3,
        opacity: 0,
        ease: 'linear',
        onComplete: done,
      })
    }

    useColorScheme()

    return {
      layoutComponent,
      layoutBeforeEnter,
      layoutEnter,
      layoutLeave,
    }
  },
})
