import { auth } from '@/composition/auth'
import { RouteConfig } from 'vue-router'

export default [
  {
    path: '/',
    name: 'Home',
    component: () =>
      lazyLoadView(
        import(
          /* webpackChunkName: 'Home.vue' */
          '@/views/Home.vue'
        )
      ),
    meta: {
      i18n: 'routes.home',
      layout: () =>
        import(
          /* webpackChunkName: 'LayoutHome.vue' */
          '@/layouts/LayoutHome.vue'
        ),
    },
  },
  {
    path: '/cotizador',
    name: 'Quotation',
    component: () =>
      lazyLoadView(
        import(
          /* webpackChunkName: 'Quotation.vue' */
          '@/views/Quotation.vue'
        )
      ),
    meta: {
      i18n: 'routes.quotation',
    },
  },
  {
    path: '/aviso-de-privacidad',
    name: 'PrivacyNotice',
    component: () =>
      lazyLoadView(
        import(
          /* webpackChunkName: 'PrivacyNotice.vue' */
          '@/views/PrivacyNotice.vue'
        )
      ),
    meta: {
      i18n: 'routes.privacyNotice',
    },
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () =>
      lazyLoadView(
        import(
          /* webpackChunkName: 'Admin.vue' */
          '@/views/admin/Admin.vue'
        )
      ),
    redirect: {
      name: 'Dashboard',
    },
    meta: {
      i18n: 'routes.admin',
      authRequired: true,
      layout: () =>
        import(
          /* webpackChunkName: 'LayoutAdmin.vue' */
          '@/layouts/LayoutAdmin.vue'
        ),
    },
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: 'Dashboard.vue' */
              '@/views/admin/Dashboard.vue'
            )
          ),
        meta: {
          i18n: 'routes.dashboard',
        },
      },
      {
        path: 'contactos',
        name: 'Contact',
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: 'Contact.vue' */
              '@/views/admin/Contact.vue'
            )
          ),
        meta: {
          i18n: 'routes.contact',
        },
      },
      {
        path: 'manometro',
        name: 'Manometer',
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: 'Manometer.vue' */
              '@/views/admin/Manometer.vue'
            )
          ),
        meta: {
          i18n: 'routes.manometer',
        },
      },
      {
        path: 'forbidden',
        name: 'Forbidden',
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: 'Forbidden.vue' */
              '@/views/admin/Forbidden.vue'
            )
          ),
        meta: {
          i18n: 'routes.forbidden',
        },
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      lazyLoadView(
        import(
          /* webpackChunkName: 'Login.vue' */
          '@/views/Login.vue'
        )
      ),
    beforeEnter(routeTo, routeFrom, next) {
      // If the user is already logged in
      if (auth.loggedIn.value) {
        // Redirect to the admin page instead
        next({ name: 'Admin' })
      } else {
        // Continue to the login page
        next()
      }
    },
    meta: {
      i18n: 'routes.login',
    },
  },
  {
    path: '/logout',
    name: 'Logout',
    beforeEnter(routeTo, routeFrom, next) {
      auth.logout()
      // Navigate back to login
      next({ name: 'Login' })
    },
    meta: {
      i18n: 'routes.logout',
      authRequired: true,
    },
  },
  {
    path: '*',
    redirect: { name: 'Home', params: { resource: '' } },
  },
] as RouteConfig[]

//===
// Private helpers
//===

// Lazy-loads view components, but with better UX. A loading view
// will be used if the component takes a while to load, falling
// back to a timeout view in case the page fails to load. You can
// use this component to lazy-load a route with:
//
// component: () => lazyLoadView(import('@/views/my-view'))
//
// NOTE: Components loaded with this strategy DO NOT have access
// to in-component guards, such as beforeRouteEnter,
// beforeRouteUpdate, and beforeRouteLeave. You must either use
// route-level guards instead or lazy-load the component directly:
//
// component: () => import('@/views/my-view')
//
function lazyLoadView(AsyncView: Promise<any>) {
  const AsyncHandler = () => ({
    component: AsyncView,
    // A component to use while the component is loading.
    loading: require('@/views/states/Loading.vue').default,
    // A fallback component in case the timeout is exceeded
    // when loading the component.
    error: require('@/views/states/Timeout.vue').default,
    // Delay before showing the loading component.
    // Default: 200 (milliseconds).
    delay: 400,
    // Time before giving up trying to load the component.
    // Default: Infinity (milliseconds).
    timeout: 10000,
  })

  return Promise.resolve({
    functional: true,
    render(h: any, { data, children }: any) {
      // Transparently pass any props or children
      // to the view component.
      return h(AsyncHandler, data, children)
    },
  })
}
