var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{staticClass:"BaseField",attrs:{"vid":_vm.vid || undefined,"name":_vm.name || _vm.label || undefined,"rules":_vm.rules || undefined,"mode":_vm.mode,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validate = ref.validate;
return [_c('div',{class:[
      'BaseField__content',
      {
        'BaseField__content--vertical': _vm.vertical,
        'BaseField__content--center': _vm.center,
      } ]},[(_vm.label && !_vm.labelRight)?_c('div',{class:_vm.labelClass,attrs:{"data-prefix":_vm.labelPrefix,"data-suffix":_vm.labelSuffix}},[_vm._t("label",[_c('label',[_vm._v(_vm._s(_vm.label))])])],2):_vm._e(),_c('div',{class:[
        'BaseField__control',
        {
          'BaseField__control--full-width': _vm.fullWidthControl,
          'BaseField__control--has-icon-left': Boolean(_vm.icon) && !_vm.iconRight,
          'BaseField__control--has-icon-right': Boolean(_vm.icon) && _vm.iconRight,
          'BaseField__control--invalid': Boolean(errors[0]),
        } ]},[_vm._t("default",null,{"errors":errors,"validate":validate}),(_vm.icon)?_c('div',{class:[
          'BaseField__icon',
          {
            'BaseField__icon--right': _vm.iconRight,
          } ],style:({
          color: _vm.getIconFill(errors[0]),
        })},[_vm._t("icon",[_c('BaseIcon',{attrs:{"name":_vm.icon}})],{"icon":_vm.icon,"iconRight":_vm.iconRight,"iconColor":_vm.getIconFill(errors[0])})],2):_vm._e(),(_vm.errorOverlay)?_c('transition',{attrs:{"name":"BaseField__overlay"}},[(_vm.error)?_c('div',{class:[
            'BaseField__overlay',
            {
              'BaseField__overlay--error': Boolean(errors[0]),
            } ]},[_vm._t("errorOverlay",[_c('BaseIcon',{attrs:{"name":_vm.errorIcon}})],{"icon":_vm.errorIcon})],2):_vm._e()]):_vm._e()],2),(_vm.label && _vm.labelRight)?_c('div',{class:_vm.labelClass,attrs:{"data-prefix":_vm.labelPrefix,"data-suffix":_vm.labelSuffix}},[_vm._t("label",[_c('label',[_vm._v(_vm._s(_vm.label))])])],2):_vm._e()]),_vm._t("hint",[(_vm.hint)?_c('p',{staticClass:"BaseField__hint"},[(_vm.hintIcon)?_c('BaseIcon',{attrs:{"name":_vm.hintIcon}}):_vm._e(),_vm._v(" "+_vm._s(_vm.hint)+" ")],1):_vm._e()],{"hint":_vm.hint}),_vm._t("error",[_c('Transition',{attrs:{"appear":"","name":"BaseField__error"}},[(errors[0])?_c('p',{staticClass:"BaseField__error"},[_c('span',[_vm._v(_vm._s(errors[0]))])]):_vm._e()])],{"error":errors[0]})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }