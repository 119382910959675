



















import { ThemeVariant } from '@/enums/ThemeVariant'
import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'BaseAlert',

  inheritAttrs: false,

  props: {
    /**
     * The alert component tag
     */
    tag: {
      type: String,
      default: 'div',
    },

    /**
     * The alert title
     */
    title: {
      type: String,
      default: '',
    },

    /**
     * The alert icon
     */
    icon: {
      type: String,
      default: 'exclamation-circle',
    },

    /**
     * The alert variant
     */
    variant: {
      type: String as PropType<ThemeVariant>,
      default: ThemeVariant.Secondary,
      validator: (value) => Object.values(ThemeVariant).includes(value),
    },

    /**
     * Applies outline style
     */
    outlined: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    actionable(): boolean {
      return Boolean(this.$attrs.to || this.$attrs.name || this.$attrs.href)
    },

    componentTag(): string {
      return this.actionable ? 'BaseLink' : this.tag
    },

    alertClass(): Array<string | { [key: string]: boolean }> {
      return [
        'BaseAlert',
        {
          [`BaseAlert--${this.variant}`]:
            Boolean(this.variant) && !this.outlined,
          [`BaseAlert--outline-${this.variant}`]: this.outlined,
          'BaseAlert--actionable': this.actionable,
        },
      ]
    },
  },
})
