




import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'BaseImage',

  props: {
    src: {
      type: String,
      required: true,
    },

    /**
     * The alt tag is required for accessibility and SEO purposes.
     *
     * If it is a decorative image, which is purely there for design reasons,
     * consider moving it to CSS or using an empty alt tag.
     */
    alt: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    // If the URL starts with @, it's interpreted as a module request.
    // Vue CLI by default aliases @ to <projectRoot>/src. (templates only)
    const isModuleRequest = computed(() => props.src.startsWith('@/'))

    // If it is a module request,
    // the exact module is not known on compile time,
    // so an expression is used in the request to create a context.
    const computedSrc = computed(() =>
      isModuleRequest.value
        ? require(`@/assets/images/${props.src.replace(
            `@/assets/images/`,
            ''
          )}`)
        : props.src
    )

    return {
      computedSrc,
    }
  },
})
