export interface Language {
  id: number
  guid: string
  locale: string
  shortLocale: string
  name: string
}

export const languages: Readonly<Record<string, Language>> = Object.freeze({
  enUs: {
    id: 0,
    guid: '4FE91D9A-5B25-4960-961D-374F274A6F86',
    locale: 'en-US',
    shortLocale: 'en',
    name: 'English',
  },
  esMx: {
    id: 1,
    guid: 'E6E766F8-3D04-4932-81CA-65CFA7E7E601',
    locale: 'es-MX',
    shortLocale: 'es',
    name: 'Español',
  },
})

export const { enUs, esMx } = languages

export const defaultLanguage: Language = esMx

export function getCurrentLanguage(locale: string): Language {
  return (
    Object.values(languages).find((language) => language.locale === locale) ||
    defaultLanguage
  )
}
