export enum ThemeVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
  Danger = 'danger',
  Light = 'light',
  Dark = 'dark',
  OutlinePrimary = 'outline-primary',
  OutlineSecondary = 'outline-secondary',
  OutlineTertiary = 'outline-tertiary',
  OutlineSuccess = 'outline-success',
  OutlineInfo = 'outline-info',
  OutlineWarning = 'outline-warning',
  OutlineDanger = 'outline-danger',
  OutlineLight = 'outline-light',
  OutlineDark = 'outline-dark',
}
