










import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'BaseInputText',

  inheritAttrs: false,

  model: {
    event: 'update',
  },

  props: {
    type: {
      type: String,
      default: 'text',
      // Only allow types that essentially just render text boxes.
      validator(value: string) {
        return [
          'email',
          'number',
          'password',
          'search',
          'tel',
          'text',
          'url',
          'hidden',
        ].includes(value)
      },
    },
  },
})
