










import {
  computed,
  defineComponent,
  PropType,
  inject,
} from '@vue/composition-api'
import {
  DEFAULT_BREAKPOINT,
  WidthProviderSymbol,
  widthRatioBreakpoints,
} from './BaseBox.vue'

export default defineComponent({
  props: {
    aspectRatio: {
      type: String,
      default: '16/9',
    },

    width: {
      type: Number as PropType<number | null>,
      default: null,
    },

    height: {
      type: Number as PropType<number | null>,
      default: null,
    },

    src: {
      type: String,
      required: true,
    },

    srcset: {
      type: String,
      default: null,
    },

    sizes: {
      type: String,
      default: null,
    },

    /**
     * The alt tag is required for accessibility and SEO purposes.
     *
     * If it is a decorative image, which is purely there for design reasons,
     * consider moving it to CSS or using an empty alt tag.
     */
    alt: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    // If the URL starts with @, it's interpreted as a module request.
    // Vue CLI by default aliases @ to <projectRoot>/src. (templates only)

    const isModuleRequest = computed(() => props.src.startsWith('@/'))

    // If it is a module request,
    // the exact module is not known on compile time,
    // so an expression is used in the request to create a context.

    const breakpointWidths = inject(WidthProviderSymbol)

    const attributes = computed(() => {
      // if (props.width) {
      //   return {
      //     src: isModuleRequest.value
      //       ? require(`@/assets/images/${props.src.replace(`@/assets/images/`, '')}`)
      //       : props.src,
      //     srcset: `${appendDimensions(
      //       props.src,
      //       (props.width * 2).toString()
      //     )} 2x`,
      //     sizes: null,
      //     width: props.width,
      //     height: props.height,
      //   }
      // }

      const attributes: Record<string, string | null> = {
        src: null,
        srcset: null,
        sizes: null,
        width: null,
        height: null,
      }

      if (breakpointWidths) {
        const srcset: string[] = []
        const sizes: string[] = []

        Object.keys(breakpointWidths.value).forEach((breakpoint) => {
          const width = breakpointWidths.value[breakpoint]
          let src = `${getImageSrcWithoutExtension(
            props.src
          )}-${breakpoint}.${getImageSrcExtension(props.src)}`

          const computedSrc = isModuleRequest.value
            ? require(`@/assets/images/${src.replace(`@/assets/images/`, '')}`)
            : src

          if (breakpoint === DEFAULT_BREAKPOINT) {
            attributes.src = computedSrc
          }

          srcset.push(`${computedSrc} ${width}w`)
          sizes.push(
            `${
              widthRatioBreakpoints[breakpoint]
                ? `(min-width: ${widthRatioBreakpoints[breakpoint]}px) `
                : ''
            }${width}px`
          )
        })

        attributes.srcset = srcset.join(', ')
        attributes.sizes = [...sizes].reverse().join(', ')
      }

      return attributes
    })

    // function appendDimensions(src: string, width: string) {
    //   const srcUrl = new URL(src)
    //   srcUrl.searchParams.append('w', width)

    //   return srcUrl.toString()
    // }

    function getImageSrcWithoutExtension(src: string) {
      return src.substr(0, src.lastIndexOf('.')) || src
    }

    function getImageSrcExtension(src: string) {
      const extension = src.split('.').pop()
      return extension ? extension.toLowerCase() : ''
    }

    return {
      attributes,
    }
  },
})
